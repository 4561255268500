
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-row>
          <el-form-item label="新闻类型" prop="type">
            <el-select
              v-model="formModel.type"
              filterable
              placeholder="请选择"
              style="width: 280px"
            >
              <el-option
                v-for="type in typeData"
                :key="type.value"
                :label="type.name"
                :value="type.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="formModel.title"
              placeholder="请输入标题"
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-form-item label="发送单位" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            size=""
            v-model="formModel.companyId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="接受者类型" prop="userType">
          <el-select
            v-model="formModel.userType"
            multiple
            placeholder="请选择"
            style="width: 90%"
          >
            <el-option
              v-for="result in popedomData"
              :key="result.id"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-form-item label="展示图" prop="imgUrl">
            <el-upload
              class="avatar-uploader"
              name="photoFile"
              :action="uploadUrl"
              :show-file-list="false"
              :headers="headers"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="fileUrl" :src="fileUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="内容" prop="content">
            <mce-editor
              ref="editor"
              uploadName="upfile"
              v-model="formModel.content"
              :url="editorUploadUrl"
              :config="editorConfig"
            ></mce-editor>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="热度" prop="heatNum">
            <el-input-number
              v-model="formModel.heatNum"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="点赞数" prop="likeNum">
            <el-input-number
              v-model="formModel.likeNum"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="显示状态" prop="status">
            <el-radio-group v-model="formModel.status">
              <el-radio :label="true">显示</el-radio>
              <el-radio :label="false">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="领取积分" prop="isReceive">
            <el-radio-group v-model="formModel.isReceive">
              <el-radio :label="true">领取</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="积分" prop="point">
            <el-input
              v-model="formModel.point"
              :min="0"
              style="width: 100px"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="需要签字" prop="isSign">
            <el-radio-group v-model="formModel.isSign">
              <el-radio :label="true">签字</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import classNewsInfoApi from "@/api/base/classNewsInfo";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";
import MceEditor from "@/components/Tinymce";
import { getToken } from "@/utils/auth";

export default {
  props: ["businessKey", "title"],
  components: {
    "mce-editor": MceEditor,
    "el-select-tree": SelectTree,
  },
  data() {
    return {
      formModel: {},
      ruleValidate: {
        companyId: [
          { required: true, message: "单位不能为空", trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: "新闻类型不能为空",
            trigger: "blur",
          },
        ],
        imgUrl: [{ required: true, message: "请上传图片", trigger: "blur" }],
        userType: [
          { required: true, message: "请选择接受者类型", trigger: "blur" },
        ],
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        status: [
          { required: true, message: "审核状态是否不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      editorConfig: {
        height: 500,
        width: 550,
      },
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      editorUploadUrl: Constant.serverUrl + "/tinymce/upload?token=" + getToken(),
      companyResult: [],
      typeData: [],
      popedomData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      //上传地址
      uploadData: {
        subFolder: "classNewsInfo",
      },
      headers: {
        Authorization: getToken(),
      },
      fileUrl: "",
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.content = self.$refs.editor.getContent();

            var userType = self.formModel.userType.join(",");
            self.formModel.userType = userType;

            if (id == null || id.length == 0) {
              return classNewsInfoApi.add(self.formModel);
            } else {
              return classNewsInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.imgUrl = res.data;
      self.fileUrl =
        res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
    },
    beforeAvatarUpload(file) {
      //const isLt2M = file.size / 1024 / 1024 < 2;
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isBMP = file.type === "image/bmp";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG && !isBMP) {
        this.$message.error("上传图片只能是 JPG/PNG/BMP 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      return isJPG;
    },
  },
  mounted: function () {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "班级新闻类型");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.typeData = jsonData.data;
    });

    var formData2 = new FormData();
    formData2.append("catalogName", "公告推送人员类型");
    dataDictionaryApi.findByCatalogName(formData2).then((response) => {
      var jsonData = response.data;
      this.popedomData = jsonData.data;
    });

    (function () {
      if (self.businessKey.length == 0) {
        return classNewsInfoApi.create();
      } else {
        return classNewsInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.formModel.content != null) {
            self.$refs.editor.setContent(self.formModel.content);
          }

          var userType = self.formModel.userType;
          if (userType != null) {
            self.formModel.userType = userType.split(",");
          }
          let imgUrl = self.formModel.imgUrl;
          if (imgUrl != null) {
            self.fileUrl =
              imgUrl + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
          }

          if (self.formModel.status == null) {
            self.formModel.status = false;
          }

          if (self.formModel.isReceive == null) {
            self.formModel.isReceive = false;
          }

          if (self.formModel.isSign == null) {
            self.formModel.isSign = false;
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>